<template>
  <div>
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on }">
        <v-chip
          x-small
          v-if="item"
          class="white--text"
          :color="get_color(item)"
          v-on="on"
          label
          >{{
            item.attendence == "P" && item.first_swipe != item.last_swipe
              ? item.normalize_duration
              : item.attendence == "P" && item.first_swipe == item.last_swipe
              ? "00:00"
              : item.attendence
          }}</v-chip
        >
      </template>
      <span v-if="item != undefined" class="white--text">
        First Swipe :
        {{
          item.attendence == "P"
            ? new Date(item.first_swipe * 1000).toLocaleTimeString()
            : "00:00"
        }}
        <br />
        Last Swipe :
        {{
          item.attendence == "P"
            ? new Date(item.last_swipe * 1000).toLocaleTimeString()
            : "00:00"
        }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  watch: {
    // item(val) {
    //   console.log("val", val);
    // },
  },
  methods: {
    get_color(item) {
      if (item.attendence != "P") {
        switch (item.attendence) {
          case "N/A":
            return "pink";
          case "A":
            return "red darken-2";
          case "H":
            return "purple";
          case "W/O":
            return "#b0b0b0";
          case "L":
            return "#81f0abb";
          case "D/A":
            return "brown";
            case "HL":
            return "#004D40";
          case "FL":
            return "#006064";
        }
      } else if (item.attendence == "P") {
        return "grey darken-2";
      }
    },
  },
};
</script>

<style></style>
